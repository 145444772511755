/* You can add global styles to this file, and also import other style files */

body {
  font-family: 'Roboto', sans-serif;
}

* {
  outline: 0;
}

.sr-only {
  display: none;
}

.table-responsive {
  overflow-x: inherit;
}

.bg-overlay {
  background-color: #000;
  opacity: 0.7;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.header-absolute {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 2;
}

.bg-alternate-dark {
  background-color: #212529 !important;
}

.bg-white-alternate {
  background: rgba(255, 255, 255, 0.2);
}

.selected-item {
  margin-top: 4px;
  background-color: #F89406 !important;
  border: 0px !important;
}

li.multiselect-item-checkbox>div:before {
  border-color: #00000088 !important;

}

hr {
  background-color: #F89406 !important;
  height: 1px;
}

.sidebar {
  .card {
    border: 0px !important;
  }

  div[role="tabpanel"] {
    max-height: 300px;
    overflow-y: auto;
  }
}

/* Devider */
.text-divider {
  margin: 2em 0;
  line-height: 0;
  text-align: center;
}

.text-divider span {
  background-color: #f5f5f5;
  padding: 0 1em;
}

.text-divider:before {
  content: " ";
  display: block;
  border-top: 1px solid #e3e3e3;
  border-bottom: 1px solid #f7f7f7;
}

/* accordian header */
.filter .accordion>.card>.card-header {
  padding: 0 !important;
  background-color: transparent !important;
  border: 0;
}


/* scrollbar */
::-webkit-scrollbar {
  width: 4px !important;
  height: 4px !important;
}
::-webkit-scrollbar-thumb {
  background: #555;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  background: #555 !important;
  border-radius: 10px !important;
}