.fill-warning svg {
  fill: $warning;
}

.fill-danger svg {
  fill: $danger;
}

.fill-success svg {
  fill: $success;
}

.fill-primary svg {
  fill: $primary;
}
