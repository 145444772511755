.divider {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
}

.divider::before {
  margin-right: 1.5rem;
}

.divider::after,
.divider::before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: .0625rem solid #e7eaf3;
  content: "";
  margin-top: .0625rem;
}

.divider::after {
  margin-left: 1.5rem;
}

.divider::after,
.divider::before {
  -ms-flex: 1 1 0%;
  flex: 1 1 0%;
  border-top: .0625rem solid #e7eaf3;
  content: "";
  margin-top: .0625rem;
}

